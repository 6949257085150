import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"

export default () => (
  <div style={{ color: `purple` }}>

      <Link to="/contact/">Contact</Link>
      <Header headerText="Hello Gatsby!" />
      
      <p>What?</p>

      <img src="https://arbordayblog.org/wp-content/uploads/2018/06/oak-tree-sunset-iStock-477164218-1080x608.jpg" alt="" />

      </div>

      
)